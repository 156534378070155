import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import Footer from '../Templates/Shared/Footer';
import Header from '../Templates/Shared/Header';
import {Container} from 'react-bootstrap';
import styles from '../styles/sitemap.module.scss';
import {Helmet} from 'react-helmet';
// import * as utils from '../Templates/Shared/Utils.js';


const pageData = ({data}) => {
  const handleScroll = () => {
    const images = document.getElementsByTagName('img');
    Array.from(images).forEach((img) => {
      if (img.dataset.src && img.src.indexOf(img.dataset.src) <= -1) {
        img.src = img.dataset.src;
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const stagingDomain = Number(process.env.StagingDomain);
  const locale = 'en';

  return (
    <div>
      <Helmet>
        {stagingDomain&&
        <meta name="robots" content="noindex,nofollow" />}
      </Helmet>
      <Header locale={locale} />
      <Container>
        <div className={styles.siteMapsection}>
          <div className={styles.siteMaprow}>
            <h4> Website pages</h4>
            <div className={styles.siteMapColumnHalf}>
              <ul className={styles.columnBreak}>
                <li>
                  <Link to={'/'}>
                    Shaw Academy Home Page
                  </Link>
                </li>
                {/* {staticPageData.nodes.map((staticField, index)=>{
                  const staticLink =
                  utils.getLocalizedPageLink(locale, staticField.url);
                  if (staticField.url==='/faq/') {
                    return <li key={index}><a href="https://help.upskillist.com/hc/en-us">
                      {staticField.title}</a></li>;
                  }
                  return (
                    <li key={index}> <Link to={staticLink}>
                      {staticField.title}</Link></li>
                  );
                })} */}
              </ul>
            </div>
          </div>
          {/*  */}
          {/* <div className={styles.siteMaprow}>
            <h4>Online Courses</h4>
            {courseData.group.map((courses, index)=>{
              return (<div className={styles.siteMapColumn} key={index}>
                <h6>{courses.fieldValue}</h6>
                <ul>
                  {courses.nodes.map((course, cindex) => {
                    const courseLink =
                    utils.getLocalizedPageLink(locale, course.url);
                    return (<li key={cindex}><Link to={courseLink}>
                      {course.coursemenuname}</Link></li>);
                  })}
                </ul>
              </div>);
            })}
          </div> */}
          {/*  */}
        </div>
      </Container>

      <Footer />
    </div>
  );
};

pageData.defaultProps = {
  data: {},
};

pageData.propTypes = {
  data: PropTypes.object,
};
export default pageData;
